import { defineStore } from 'pinia';

type BreadcrumbItem = {
  label: string;
  to?: string;
  target?: string;
};

interface Breadcrumb {
  items: BreadcrumbItem[] | null;
}

export const useBreadcrumb = defineStore('breadcrumb', {
  state: (): Breadcrumb => {
    return {
      items: null,
    };
  },

  actions: {
    update(breadcrumb: BreadcrumbItem[]) {
      this.reset();
      this.items = breadcrumb;

      return this;
    },
    reset() {
      this.items = null;
    },
  },
});
